<template>
  <app-header />

    <nav class="social">
      <ul>
        <li>
          <a href="https://linkedin.com/in/matteland"
            ><i class="fab fa-linkedin"></i> LinkedIn</a
          >
        </li>
        <li>
          <a href="https://twitter.com/IntegerMan"
            ><i class="fab fa-twitter"></i> Twitter / X</a
          >
        </li>
        <li>
          <a href="https://bsky.app/profile/integerman.bsky.social"
            ><i class="fas fa-cloud"></i> Bluesky</a>
        </li>
        <li>
          <a href="https://github.com/integerman"
            ><i class="fab fa-github"></i> GitHub</a
          >
        </li>
        <li>
          <a href="https://mattondatascience.com"
            ><i class="fab fa-youtube"></i> YouTube</a
          >
        </li>
        <li>
          <a href="https://matteland.medium.com/membership"
            ><i class="fab fa-medium"></i> Medium</a
          >
        </li>
        <li>
          <a href="https://sessionize.com/matt-eland/"
            ><i class="fas fa-megaphone"></i> Sessionize</a
          >
        </li>
        <li>
          <a href="https://mvp.microsoft.com/en-us/PublicProfile/5005207?fullName=Matt%20Eland"
            ><i class="fas fa-trophy"></i> MVP Profile</a
          >
        </li>
      </ul>
    </nav>

    <div class="hr"></div>

    <section>
      <h1 class="mt-5">Here for AI Con USA?</h1>
      <p style="font-size: 150%">
      <router-link :to="{ name: 'Jupyter' }"
        ><i class="fad fa-snake"></i> Get Jupyter Workshop Resources Now</router-link>
      </p>
    </section>

    <div class="hr"></div>

  <h2 class="mt-5">Featured Content</h2>
  <section class="columns featured mt-2 is-multiline">
    <article class="column is-one-third-desktop">
      <a href="https://mattondatascience.com">
        <div class="card">
          <div class="card-image">
            <figure class="image">
              <img src="@/assets/MattOnDS.png" alt="Matt on Data Science Screenshot">
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image is-48x48">
                  <img src="@/assets/Beaker.png" alt="Matt on Data Science Icon">
                </figure>
              </div>
              <div class="media-content">
                <p class="title is-5"><a href="https://mattondatascience.com">Matt on Data Science</a></p>
                <p class="subtitle is-6">YouTube Channel</p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </article>
    <article class="column is-one-third-desktop">
      <a href="https://accessibleai.dev">
        <div class="card">
          <div class="card-image">
            <figure class="image">
              <img src="@/assets/AccessibleAI.png" alt="AccessibleAI.dev Screenshot">
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image is-48x48">
                  <img src="@/assets/Beaker.png" alt="Accessible AI Icon">
                </figure>
              </div>
              <div class="media-content">
                <p class="title is-5"><a href="https://AccessibleAI.dev">Accessible AI</a></p>
                <p class="subtitle is-6">Data Science &amp; AI Blog</p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </article>
    <article class="column is-one-third-desktop">
      <a href="https://NewDevsGuide.com">
        <div class="card">
          <div class="card-image">
            <figure class="image">
              <img src="@/assets/NewDevsGuide.png" alt="NewDevsGuide.com Screenshot">
            </figure>
          </div>

          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image is-48x48">
                  <img src="@/assets/NewDevsGuideSquare.png" alt="New Dev's Guide Icon">
                </figure>
              </div>
              <div class="media-content">
                <p class="title is-5"><a href="https://NewDevsGuide.com">New Dev's Guide</a></p>
                <p class="subtitle is-6">Software Engineering Blog</p>
              </div>
            </div>
          </div>    
        </div>
      </a>
    </article>
    <article class="column is-one-third-desktop">
      <a href="https://www.amazon.com/Refactoring-Safely-improve-applications-technical/dp/1835089984/">
        <div class="card">
          <div class="card-image">
            <figure class="image">
              <img src="@/assets/Books.png" alt="A synthwave background with the Refactoring with C# book in the foreground">
            </figure>
          </div>

          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image is-48x48">
                  <img src="@/assets/RefactoringSm.jpg" alt="Refactoring with C# Cover">
                </figure>
              </div>
              <div class="media-content">
                <p class="title is-5"><a href="https://www.amazon.com/Refactoring-Safely-improve-applications-technical/dp/1835089984/">Refactoring with C#</a> (Book)</p>
                <p class="subtitle is-6">Safely &amp; Effectively Refactor C# Code</p>
              </div>
            </div>
          </div>    
        </div>
      </a>
    </article>
    <article class="column is-one-third-desktop">
      <a href="https://www.linkedin.com/learning/azure-ai-engineer-associate-ai-102-cert-prep-implement-computer-vision-solutions/introducing-computer-vision-on-azure">
        <div class="card">
          <div class="card-image">
            <figure class="image">
              <img src="@/assets/VisionCourseAvatar.png" width="600" height="334" alt="Computer Vision on Azure LinkedIn Banner">
            </figure>
          </div>

          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image is-48x48">
                  <i class="fab fa-linkedin fa-3x"></i>
                </figure>
              </div>
              <div class="media-content">
                <p class="title is-5"><a href="https://www.linkedin.com/learning/azure-ai-engineer-associate-ai-102-cert-prep-implement-computer-vision-solutions/introducing-computer-vision-on-azure">Azure Computer Vision</a> (Course)</p>
                <p class="subtitle is-6">Azure AI Engineer (AI-102) Cert Prep</p>
              </div>
            </div>
          </div>    
        </div>
      </a>
    </article>
    <article class="column is-one-third-desktop">
      <a href="https://KillAllDefects.com">
        <div class="card">
          <div class="card-image">
            <figure class="image">
              <img src="@/assets/KillAllDefects.png" alt="KillAllDefects.com Screenshot">
            </figure>
          </div>

          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image is-48x48">
                  <img src="@/assets/KADLogo.png" alt="Kill All Defects Icon">
                </figure>
              </div>
              <div class="media-content">
                <p class="title is-5"><a href="https://KillAllDefects.com">Kill All Defects</a></p>
                <p class="subtitle is-6">Software Quality Blog</p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </article>
  </section>

  <div class="hr"></div>
  <h2 class="mt-5">Talk Resources</h2>
  <talk-resources-component />
  <div class="hr"></div>

  <h2 class="mt-5">Content</h2>

  <section class="is-flex is-flex-wrap-wrap content-list">


    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Written Content
        </p>
      </header>
      <div class="card-content">
        <small>
          I write content on <a href="https://AccessibleAI.dev">Accessible AI</a> and <a
          href="https://killalldefects.com/">Kill All Defects</a> and cross-post it
          to other platforms. I'm also <a href="https://NewDevsGuide.com">writing a book on software
          scalability</a>.
        </small>
        <ul>
          <li>
            Technical Articles:
            <ul>
              <li><a href="https://AccessibleAI.dev/"><i class="fad fa-blog"></i> Accessible AI
                <small>(Data Science &amp; AI Blog)</small></a></li>
              <li><a href="https://NewDevsGuide.com/"><i class="fad fa-blog"></i> The New Dev's Guide
                <small>(Software Engineering Blog)</small></a></li>
              <li><a href="https://killalldefects.com/"><i class="fad fa-blog"></i> Kill All Defects
                <small>(Software Quality Blog)</small></a></li>
              <li><a href="https://matteland.medium.com/membership"><i class="fab fa-medium"></i> Medium
                Articles <small>(cross-posted)</small></a>
              </li>
              <li><a href="https://dev.to/integerman"><i class="fab fa-dev"></i> Dev.to
                Articles <small>(cross-posted)</small></a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Video Content
        </p>
      </header>
      <div class="card-content">
        <small>
          This section features some recorded versions of past talks as well as my <a
          href="https://www.youtube.com/c/MattEland">YouTube channel</a>.
        </small>
        <ul>
          <li>
            YouTube Content
            <ul>
              <li><a href="https://www.youtube.com/c/MattEland">
                <i class="fab fa-youtube swap"></i> Matt on Data Science (YouTube Channel)</a>
              </li>
              <li><a href="https://www.youtube.com/playlist?list=PL_4SjWezd5HwRsKZPZENzxkMwmAyeCiCo">
                <i class="fad fa-films swap"></i> Intro to Data Science in the Cloud Playlist</a>
              </li>

              <li><a href="https://www.youtube.com/playlist?list=PL_4SjWezd5HydkuN1AnaVuw--8cjZZZRm">
                <i class="fad fa-films swap"></i> C# &amp; .NET Playlist</a>
              </li>

              <li><a href="https://www.youtube.com/playlist?list=PL_4SjWezd5Hx4gJ6R4YpPp3kICzt21jRQ">
                <i class="fad fa-films swap"></i> Recorded Talks and Interviews Playlist</a>
              </li>

            </ul>
          </li>
          <li>
            Highlighted Talk Recordings:
            <ul>
              <li><a href="https://www.youtube.com/watch?v=OHVoJV42lI4"><i class="fad fa-chalkboard-teacher swap"></i>
                Intro
                to
                App Architecture and Scalability <small>(CSC 2021)</small></a>
              </li>
              <li><a href="https://www.youtube.com/watch?v=3Lupk29TqCc"><i class="fad fa-chalkboard-teacher swap"></i>
                Expanding your .NET Testing Toolbox <small>(GLUGNET 2021)</small></a>
              </li>
              <li><a href="https://www.pluralsight.com/courses/codemash-session-25"><i
                class="fad fa-chalkboard-teacher swap"></i> Technical Debt Must Die
                <small>(CodeMash
                  2020
                  via
                  Pluralsight)</small></a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <current-talks-component />
    <past-talks-component />
  </section>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import PastTalksComponent from "@/components/PastTalksComponent";
import CurrentTalksComponent from "@/components/CurrentTalksComponent";
import TalkResourcesComponent from "@/components/TalkResourcesComponent";

export default {
  name: "HomeView",
  components: {
    TalkResourcesComponent,
    CurrentTalksComponent,
    PastTalksComponent,

    AppHeader
  }
};
</script>

<style scoped>

section.columns article.column {
  max-width: 623px;
}


</style>