<template>
  <ul v-if="talks.length">
    <li v-for="talk in talks" :key="talk.slug">
      <a :href="talk.sessionizeUrl" target="_blank"
        ><i :class="talk.icon"></i>
        {{ talk.title }}
      </a>
      <p v-if="talk.blurb" class="blurb">
        {{ talk.blurb }}
      </p>

      <ul v-if="showDelivered && talk.deliveredAt.length" class="delivered">
        <talk-list-item-component
          v-for="event in talk.deliveredAt"
          :key="event.name"
          :event="event"
        />
      </ul>

      <ul v-if="showUpcoming && talk.upcoming.length" class="upcoming">
        <talk-list-item-component
          v-for="event in talk.upcoming"
          :key="event.name"
          :event="event"
        />
      </ul>

      <p
        v-if="
            includeNotDelivered &&
            !talk.upcoming.length &&
            !talk.deliveredAt.length &&
            showDetails
          "
        class="notDelivered"
      >
        This talk has not yet been delivered anywhere, but I'd like to give it
        in the community. If you're interested in it for your event, please
        <router-link :to="{ name: 'ContactMe' }">contact me</router-link>
        .
      </p>
    </li>
  </ul>
  <p v-if="!talks.length">No talks are listed. There may have been an error.</p>
</template>

<script>
import TalkListItemComponent from "@/components/TalkListItemComponent";

export default {
  name: "TalksListComponent",
  components: { TalkListItemComponent },
  props: {
    includeUpcoming: {
      type: Boolean,
      default: true,
    },
    showUpcoming: {
      type: Boolean,
      default: true,
    },
    showDelivered: {
      type: Boolean,
      default: true,
    },
    showDetails: {
      type: Boolean,
      default: true,
    },
    includePast: {
      type: Boolean,
      default: true,
    },
    includeNotDelivered: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    talks() {
      return this.$store.state.currentTalks.filter((t) => {
        if (this.includeUpcoming && t.upcoming.length) {
          return true;
        }
        if (this.includePast && t.deliveredAt.length) {
          return true;
        }
        if (
          this.includeNotDelivered &&
          !t.deliveredAt.length &&
          !t.upcoming.length
        ) {
          return true;
        }

        return false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.blurb {
  font-size: 80%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
li > p:first-of-type {
  margin-top: 0.5rem;
}

li ul {
  margin-left: 1rem;
  &:first-child {
    margin-top: 1rem;
  }

}

.notDelivered {
  margin-top: 1rem;
}
</style>
