import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import DoggoView from "@/views/Talks/DoggoView";
import TryDataScienceView from "@/views/Talks/TryDataScienceView";
import VisualizingCode from "@/views/Talks/VisualizingCode";
import Jupyter from "@/views/Talks/Jupyter";
import IntroducingAutoMLView from "@/views/Talks/IntroducingAutoMLView";
import DieHardView from "@/views/Talks/DieHardView";
import ContactMeView from "@/views/ContactMeView";
import NotFoundView from "@/views/NotFoundView";
import TalksView from "@/views/TalksView";
import ChatView from "@/views/ChatView";

const siteRoot = "https://MattEland.dev";
const defaultThumbnail = siteRoot + require("../assets/thumbnail.png"); // TODO: Store on CDN or Blob

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: {
      title: "MattEland.dev", // TODO: Set title, generate title, og:title, og:twitter:title
      image: defaultThumbnail, // TODO: image should generate image, og:image and og:twitter:image tags
      imageWidth: 800, // TODO: generate og:image:width
      imageHeight: 402, // TODO: generate og:image:height
    },
  },
  {
    path: "/talks",
    name: "Talks",
    component: TalksView,
    meta: {
      title: "Talks by Matt Eland",
      image: defaultThumbnail,
      imageWidth: 800,
      imageHeight: 402,
    },
  },
  {
    path: "/talks/doggo",
    name: "Doggo",
    component: DoggoView,
    meta: {
      title: "Automating my Dog with Azure Cognitive Services",
      image: defaultThumbnail,
      imageWidth: 800,
      imageHeight: 402,
    },
  },
  {
    path: "/talks/diehard",
    name: "DieHard",
    component: DieHardView,
    meta: {
      title: "Is Die Hard a Christmas Movie? Let's ask Azure!",
      image: defaultThumbnail,
      imageWidth: 800,
      imageHeight: 402,
    },
  },
  {
    path: "/talks/tryds",
    name: "TryDS",
    component: TryDataScienceView,
    meta: {
      title: "Stand Back; I'm going to try Data Science!",
      image: defaultThumbnail,
      imageWidth: 800,
      imageHeight: 402,
    },
  },
  {
    path: "/talks/visualizingcode",
    name: "VisualizingCode",
    component: VisualizingCode,
    meta: {
      title: "Visualizing Code",
      image: defaultThumbnail,
      imageWidth: 800,
      imageHeight: 402,
    },
  },
  {
    path: "/talks/jupyter",
    name: "Jupyter",
    component: Jupyter,
    meta: {
      title: "Jupyter Workshop",
      image: defaultThumbnail,
      imageWidth: 800,
      imageHeight: 402,
    },
  },
  {
    path: "/talks/AzureMLPythonSDK",
    name: "IntroducingAutoML",
    component: IntroducingAutoMLView,
    meta: {
      title: "Introducing Auto ML with Python and the Azure ML SDK",
      image: defaultThumbnail,
      imageWidth: 800,
      imageHeight: 402,
    },
  },
  {
    path: "/chat",
    name: "Chat",
    component: ChatView,
    meta: {
      title: "Accessible AI Chat",
      image: defaultThumbnail,
      imageWidth: 800,
      imageHeight: 402,
    },
  },
  {
    path: "/contact",
    name: "ContactMe",
    component: ContactMeView,
    meta: {
      title: "Contact Matt Eland",
      image: defaultThumbnail,
      imageWidth: 800,
      imageHeight: 402,
    },
  },
  // Old Talk Redirects:
  {
    path: "/tryds",
    redirect: "/talks/tryds",
  },
  {
    path: "/py",
    redirect: "/talks/jupyter",
  },
  {
    path: "/pie",
    redirect: "/talks/jupyter",
  },
  {
    path: "/jupyter",
    redirect: "/talks/jupyter",
  },
  {
    path: "/jupiter",
    redirect: "/talks/jupyter",
  },
  {
    path: "/doggo",
    redirect: "/talks/doggo",
  },
  {
    path: "/diehard",
    redirect: "/talks/diehard",
  },
  {
    path: "/visualizingcode",
    redirect: "/talks/visualizingcode",
  },
  {
    path: "/azuremlpythonsdk",
    redirect: "/talks/AzureMLPythonSDK",
  },
  {
    path: "/azureml",
    redirect: "/talks/AzureMLPythonSDK",
  },  
  // Catch all 404
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundView,
    meta: {
      title: "MattEland.dev",
      image: defaultThumbnail,
      imageWidth: 800,
      imageHeight: 402,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function createMeta(name, content) {
  const tag = document.createElement("meta");
  tag.name = name;
  tag.setAttribute("data-vue-router-controlled", "");
  tag.setAttribute("content", content);

  return tag;
}

function addSeoTags(to) {
  const nearest = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta);

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // If a route with a title was found, set the document (page) title to that value.
  const title = nearest.meta.title;
  document.title = title;

  let tag = createMeta("title", title);
  tag.setAttribute("property", "og:title");
  document.head.appendChild(tag);

  tag = createMeta("og:title", title);
  document.head.appendChild(tag);

  tag = createMeta("og:twitter:title", title);
  document.head.appendChild(tag);

  let image = nearest.meta.image;
  if (!image) {
    image = defaultThumbnail;
  }

  tag = createMeta("image", image);
  tag.setAttribute("property", "og:image");
  document.head.appendChild(tag);

  tag = createMeta("og:image", image);
  document.head.appendChild(tag);

  tag = createMeta("og:twitter:image", image);
  document.head.appendChild(tag);

  tag = createMeta("og:image:width", nearest.meta.imageWidth);
  document.head.appendChild(tag);

  tag = createMeta("og:image:height", nearest.meta.imageHeight);
  document.head.appendChild(tag);

  // Always update canonical and og:url to current URL
  const url = siteRoot + to.fullPath;

  tag = createMeta("og:url", url);
  document.head.appendChild(tag);

  tag = document.createElement("link");
  tag.setAttribute("rel", "canonical");
  tag.setAttribute("href", url);
  tag.setAttribute("data-vue-router-controlled", "");
  document.head.appendChild(tag);
}

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  addSeoTags(to);

  next();
});

export default router;
