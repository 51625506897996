<template>
  <app-header header="Contact Me" message="Right now the best way of contacting me is via my social profiles" />
  <p class="mt-4">
    The best way of reaching out at the moment is to message me on LinkedIn or Twitter / X. You can also <a href="mailto:matt@mattondatascience.com">email me about business inquiries</a>.
  </p>
  <social-card-component />
</template>

<script>
import SocialCardComponent from "@/components/SocialCardComponent";
import AppHeader from "@/components/AppHeader";
export default {
  name: "ContactMeView.vue",
  components: { AppHeader, SocialCardComponent },
};
</script>

<style scoped lang="scss">
p {
  margin-bottom: 1rem;
}
</style>
