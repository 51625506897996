<template>
  <iframe
    width="560"
    height="315"
    :src="url"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
</template>

<script>
export default {
  name: "YouTubeEmbed",
  props: {
    video: {
      type: String,
      required: true,
    }
  },
  computed: {
    url() {
      return `https://www.youtube.com/embed/${this.video}`;
    }
  }
};
</script>

<style scoped></style>
