<template>
  <app-header header="Azure ML Python SDK Resources" message="Here's a collection of resources related to my Azure ML talks."/>

  <!-- YouTube Embed -->
  <you-tube-embed video="Ua8Wtc2qIzk" />

  <section class="is-flex is-flex-wrap-wrap content-list mt-5">
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Key Resources
        </p>
      </header>
      <div class="card-content">
        <small>
          Here are a few recommended resources:
        </small>
        <ul>
          <li><a target="_blank" href="https://1drv.ms/p/s!AiBfiXC6wirjj8AOl35CkB5XoDME8Q?e=3UcNmI"><i class="fad fa-presentation-screen"></i> Automating Machine Learning with Python and Azure Presentation Slides</a></li>
          <li><a target="_blank" href="https://1drv.ms/p/s!AiBfiXC6wirjj78gWVafdzXgTKLnoQ?e=iBTAJT"><i class="fad fa-presentation-screen"></i> Empowering ML Presentation Slides</a></li>
          <li><a target="_blank" href="https://github.com/IntegerMan/HockeyPenaltyPrediction"><i class="fab fa-github"></i> Hockey Penalty Prediction GitHub Repository</a></li>
          <li><a target="_blank" href="https://github.com/IntegerMan/DieHardSolver"><i class="fab fa-github"></i> Die Hard Classification GitHub Repository</a></li>
          <li><a target="_blank" href="https://AccessibleAI.dev"><i class="fad fa-globe"></i> Accessible AI (Data Science Blog)</a></li>
          <li><a target="_blank" href="https://MattOnDataScience.com"><i class="fab fa-youtube"></i> Matt on Data Science YouTube Channel</a></li>
        </ul>
      </div>
    </div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Azure ML Articles
        </p>
      </header>
      <div class="card-content">
        <small>
          If your focus is on the Azure ML overall, these are good articles to start with.
        </small>
        <ul>
          <li><a target="_blank" href="https://accessibleai.dev/post/azuremltour/"><i class="fad fa-cloud-plus swap"></i> Azure Machine Learning Studio Tour</a></li>
          <li><a target="_blank" href="https://accessibleai.dev/post/interpreting_confusion_matrixes/"><i class="fad fa-table"></i> Understanding Confusion Matrixes</a></li>
          <li><a target="_blank" href="https://accessibleai.dev/post/regression_metrics/"><i class="fad fa-chart-line"></i> Understanding Regression Metrics</a></li>
          <li><a target="_blank" href="https://accessibleai.dev/post/azureml_compute_resources/"><i class="fad fa-microchip"></i> Azure Machine Learning Compute Resources</a></li>
        </ul>
      </div>
    </div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Azure ML Python SDK Resources
        </p>
      </header>
      <div class="card-content">
        <small>
          If your focus is on the Azure ML Python SDK, check out these links
        </small>
        <ul>
          <li><a target="_blank" href="https://accessibleai.dev/post/introducingazuremlpythonsdk/"><i class="fad fa-flask"></i> Introducing Auto ML with Python and the Azure ML SDK Article</a></li>
          <li><a target="_blank" href="https://accessibleai.dev/post/azureml-sdk-connect-to-workspace/"><i class="fad fa-laptop-file"></i> Connecting to an Azure Workspace from the Python SDK</a></li>
          <li><a target="_blank" href="https://accessibleai.dev/post/registering-pandas-dataframes-as-azure-datasets/"><i class="fad fa-table-cells-large"></i> Converting Pandas DataFrames to Azure Datasets</a></li>
          <li><a target="_blank" href="https://accessibleai.dev/post/azure_ml_sdk_create_compute_resources/"><i class="fad fa-microchip"></i> Managing Compute Resources from the Azure ML Python SDK</a></li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import YouTubeEmbed from "@/components/YouTubeEmbed";
export default {
  name: "IntroducingAutoMLView",
  components: { YouTubeEmbed, AppHeader }
};
</script>

<style scoped lang="scss">

</style>
