import { createStore } from "vuex";

export default createStore({
  state: {
    currentTalks: [
      {
        slug: "PythonAzureAutoML",
        icon: "fad fa-snake swap",
        title: "Automating Machine Learning with Python and Azure",
        blurb: "Exploring AutomatedML in Python using the Azure ML Python SDK",
        abstract: [
          "In this session we'll explore Azure Machine Learning Studio and how it can help novices, journeymen, and experts on their machine learning journey.",
          "Specifically we'll explore AutomatedML and machine learning without deep data science knowledge, the machine learning designer for intermediate users, and the Python SDK for those who prefer to code in Python but take advantage of Azure's cloud offerings.",
          "We'll explore data and compute management as well as integration with other aspects of Azure such as databricks and synapse analytics and close with a discussion of Azure Cognitive Services for those interested in consuming pre-built machine learning models.",
          "By the time we're done, you'll see how Azure is a frighteningly powerful platform for all flavors of data scientists - including those without a deep data science background.",
        ],
        sessionizeUrl:
          "https://sessionize.com/s/matt-eland/automating-machine-learning-with-python-and-azure/49272",
        upcoming: [
          {
            name: "Azure Back to School 2022",
            link: "https://azurebacktoschool.github.io/edge%20case/azure-back-to-school-2022-sessions/",
            location: "Online",
            date: "9/29/22",
            room: "",
            time: "",
          },
          {
            name: "Indy.Code()",
            link: "https://indycode.amegala.com/Sessions/1388",
            location: "Indianapolis, IN",
            date: "10/19/2022",
            room: "Carey",
            time: "12:20 PM Eastern",
          },
        ],
        deliveredAt: [
          {
            name: "PyOhio 2022",
            link: "https://www.pyohio.org/2022/program/talks/introducing-automated-machine-learning-with-python-and-azure",
            location: "Columbus, OH",
            date: "7/30/2022",
            room: "",
            time: "2:47 PM Eastern",
          },
          {
            name: "MemPy",
            link: "https://www.meetup.com/memphis-technology-user-groups/events/ptqnwrydcjbbc/",
            location: "Memphis, TN",
            date: "6/20/2022",
            room: "",
            time: "7:00 PM Eastern",
          },
        ],
      },
      {
        slug: "BeginningMLNetAutoML",
        icon: "fad fa-brain swap",
        title: "Beginning Machine Learning in C# with ML.NET AutoML",
        sessionizeUrl: "https://sessionize.com/s/matt-eland/beginning-machine-learning-in-c-with-ml.net-automl/46757",
        blurb:
          "Introducing Automated on-device Machine Learning in C# with ML.NET",
        abstract: [
          "Did you know you can train and use machine learning inside of your .NET applications without needing detailed knowledge of machine learning algorithms? In this talk we'll explore the ML.NET AutoML API capabilities and how accessible machine learning in C# really is as we write C# code to solve machine learning problems.",
          "We'll focus heavily on the automatic algorithm selection features of AutoML in ML.NET and talk about the various tasks it can achieve before drilling deeper to apply AutoML to solve a multi-class classification problem. We'll train a machine learning model and have it predict video game ESRB ratings for a few hypothetical games provided by the audience, then host this new model in a .NET 6 minimal Web API project in ASP .NET Core.",
          "We'll also explore evaluating model performance, ideal training times, and how trained models can be saved and loaded for use in production applications, as well as some places you can go to learn more about ML.NET and machine learning in general.",
        ],
        deliveredAt: [],
        upcoming: [],
      },
      {
        slug: "Doggo",
        icon: "fad fa-dog swap",
        title: "Automating my Dog with Azure Cognitive Services",
        sessionizeUrl: "https://sessionize.com/s/matt-eland/automating-my-dog-with-azure-cognitive-services/47228",
        blurb:
          "Showing off Azure Cognitive Services in C# the most absurd way possible",
        abstract: [
          "Like many terrier owners, I have a problem. My dog is overworked from the constant need to monitor multiple streets to bark at squirrels or passers by. I'd like to free up more of his time and energy for snuggling and play but the outdoors must still be monitored. Thankfully, it turns out that much of what my dog does, Azure Cognitive Services can help with.",
          "In this talk we'll use this absurd premise to explore progressively enhancing applications through the Azure Cognitive Services speech, vision, and text APIs. We'll look at object detection, facial APIs, text to speech, speech to text, and language understanding.",
          "By the end of this session you'll have more of an understanding of what Azure Cognitive Services can do and the basics of how to interact with them from code so that you, like my dog, can take advantage of pre-trained machine learning models to enhance devote more of your energy to other areas.",
        ],
        deliveredAt: [
          {
            name: "KCDC 2023",
            link: "https://kcdc.info",
            location: "Online",
            date: "6/22/22",
            room: "",
            time: "",
          },
          {
            name: "SciFiDevCon 2023",
            link: "https://www.meetup.com/central-ohio-net-developers-group-condg/events/283748967/",
            location: "Online",
            date: "8/24/22",
            room: "",
            time: "",
          },
          {
            name: "Central Ohio .NET Developer Group (CONDG)",
            link: "https://www.meetup.com/central-ohio-net-developers-group-condg/events/283748967/",
            location: "Columbus, OH",
            date: "8/24/22",
            room: "",
            time: "6:00 PM Eastern",
          },
        ],
        upcoming: [],
      },
      {
        slug: "EmpoweringDSwithAzure",
        icon: "fad fa-dumbbell",
        title: "Empowering your Data Science Experiments with Azure Machine Learning",
        blurb:
          "A high level overview of machine learning on Azure",
        sessionizeUrl: "https://sessionize.com/s/matt-eland/empowering-your-data-science-experiments-with-azur/50789",
        abstract: [
          "Azure Machine Learning Studio has something for all experience levels of data scientist. In this talk we'll explore what Azure Machine Learning Studio is and how it can help novice, intermediate, and advanced data scientists empower their data science experiments.",
          "We'll start by exploring Automated ML and how it helps data scientists focus on the task they're trying to accomplish while Azure discovers the optimal algorithms and hyperparameters for their experiments - without requiring any code on their part.",
          "Next, we'll explore the Machine Learning Studio designer and how it supports more advanced no-code or low-code approaches to build repeatable machine learning pipelines.",
          "After that we'll discuss the Azure ML Python SDK and how it allows advanced users to customize their experiments, use their own compute resources, and fine-tune and automate the tasks they're trying to accomplish.",
          "By the end of this talk you'll see how Azure Machine Learning Studio reduces barriers to entry and propels experiments further by helping novice, intermediate, and advanced data scientists train, evaluate, manage, and deploy their machine learning models and related datasets."
        ],
        deliveredAt: [
          {
            name: "Franklin University Doctoral Student Association Conference",
            link: "https://fuse.franklin.edu/dsa-conf/2022/presentations/25/",
            location: "Online",
            date: "6/25/22",
            room: "",
            time: "12:35 PM Eastern",
          },
        ],
        upcoming: [
          {
            name: "Azure Back to School 2022",
            link: "https://azurebacktoschool.github.io/edge%20case/azure-back-to-school-2022-sessions/",
            location: "Online",
            date: "9/29/22",
            room: "",
            time: "",
          },
        ],
      },
      {
        slug: "DieHard",
        icon: "fad fa-hat-santa swap",
        title: "Is Die Hard a Christmas Movie? Let's ask Azure!",
        sessionizeUrl: "https://sessionize.com/s/matt-eland/is-die-hard-a-christmas-movie-lets-ask-azure/44906",
        blurb:
          "Answering one of the most divisive questions out there - using Azure Machine Learning",
        abstract: [
          "When it comes to popular Christmas movies there's a recurring debate as to whether or not that list of movies should include the 1988 film Die Hard. Thankfully, machine learning has been applied to this problem and we have an answer.",
          "We'll start off by using Python, Pandas, and Jupyter Notebooks to analyze and clean movie data and then prepare it for model training while avoiding factors that might introduce bias.",
          "After that we'll explore using the beginner-friendly features of Azure Machine Learning Studio's Automated ML to train and evaluate a classification model.",
          "Finally, we'll deploy the trained model to Azure as a web service and see what it has to say about Die Hard.",
          "By the time we're done you'll know the truth about Die Hard and have a deeper understanding of machine learning experiments and some common beginner-friendly tools involved in machine learning.",
        ],
        deliveredAt: [
          {
            name: "Festive Tech Calendar 2021",
            link: "https://www.youtube.com/watch?v=_s3uBi7l1L0&feature=youtu.be",
            location: "Online",
            date: "12/4/22",
            room: "",
            time: "",
          },
        ],
        upcoming: [
          {
            name: "Azure Back to School 2022",
            link: "https://azurebacktoschool.github.io/edge%20case/azure-back-to-school-2022-sessions/",
            location: "Online",
            date: "9/7/22",
            room: "",
            time: "",
          },
          {
            name: "Momentum 2022",
            link: "https://momentumdevcon.com/session/340347",
            location: "Cincinnati, OH",
            date: "10/20/22",
            room: "River B",
            time: "11:10 AM Eastern",
          },
          {
            name: "TechBash 2022",
            link: "https://www.techbash.com/sessions",
            location: "Mount Pocono, PA",
            date: "11/10/22",
            room: "Suite 8",
            time: "11:30 AM Eastern",
          },
        ],
      },
      {
        slug: "TryDS",
        icon: "fad fa-flask swap",
        title: "Stand Back; I'm going to try Data Science!",
        sessionizeUrl: "https://sessionize.com/s/matt-eland/stand-back-im-going-to-try-data-science/44728",
        blurb:
          "A beginner-friendly overview of the data science and machine learning landscape",
        abstract: [
          "Curious about data science and its relation to software engineering? Want to know how to dabble in artificial intelligence or machine learning side projects before taking the plunge? Come check out this session.",
          "In this session I'll highlight my own journey in layering data science skills on top of a software engineering background. I'll teach you the terms, roles, languages, libraries, and technologies you'll encounter and help you understand what aspects of math and programming are helpful in setting down this journey.",
          "You'll discover easy ways to get started with Python, R, and get connected to the data science community. I'll show you how to discover public datasets and visualizations to help inspire your own journey. By the time the session is finished, you'll know how to find out if data science is a good fit for you and how to take it to the next level if you discover you like it.",
        ],
        deliveredAt: [
          {
            name: "StirTrek 2022",
            link: "https://stirtrek.com/speakers/2022/Matt-Eland.html#abstract",
            location: "Columbus, OH",
            date: "1/14/2022",
            room: "Aware (Theater 28)",
            time: "1:00 PM Eastern",
          },
          {
            name: "CodeMash 2022",
            link: "https://www.codemash.org/wp-content/uploads/2022/08/CodeMashSchedule_2022.pdf",
            location: "Sandusky, OH",
            date: "1/14/2022",
            room: "Rosewood",
            time: "12:15 PM Eastern",
          },
        ],
        upcoming: [
          {
            name: "TechBash 2022",
            link: "https://www.techbash.com/sessions",
            location: "Mount Pocono, PA",
            date: "11/9/2022",
            room: "Suite 8",
            time: "2:50 PM Eastern",
          },
        ],
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
