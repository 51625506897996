<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        Social Links
      </p>
    </header>
    <div class="card-content">
      <small>
        I'm active on social media in a few key areas and this is the best way to stay in touch and keep
        up with my latest content and activities.
      </small>
      <ul>
        <li><a href="https://LinkedIn.com/in/matteland"><i class="fab fa-linkedin"></i> LinkedIn
          <small>(Please include a note when connecting)</small></a>
        </li>
        <li><a href="https://www.twitter.com/IntegerMan"><i class="fab fa-twitter"></i> Twitter / X</a></li>
        <li><a href="https://bsky.app/profile/integerman.bsky.social"
            ><i class="fas fa-cloud"></i> Bluesky</a
          ></li>
        <li><a href="https://www.youtube.com/c/MattEland"><i class="fab fa-youtube"></i> YouTube</a></li>
        <li><a href="https://github.com/IntegerMan"><i class="fab fa-github"></i> GitHub</a></li>
        <li><a href="https://techhub.social/@IntegerMan"
            ><i class="fab fa-mastodon"></i> Mastodon</a
          ></li>
        <li><a href="https://sessionize.com/matt-eland/"><i class="fad fa-chalkboard-teacher swap"></i>
          Sessionize</a></li>
        <li><a href="https://integerman.itch.io/"><i class="fad fa-gamepad-alt"></i> Itch.io</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialCardComponent"
};
</script>

<style scoped lang="scss">

</style>
