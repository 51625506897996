<template>
  <app-header header="Is Die Hard a Christmas Movie? Let's ask Azure!" message="Here's a collection of resources related to my 'Is Die Hard a Christmas Movie? Let's ask Azure!' talk."/>

  <!-- YouTube Embed -->
  <you-tube-embed video="WUJT2wpCjT0" />

  <section class="is-flex is-flex-wrap-wrap content-list mt-5">
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Key Resources
        </p>
      </header>
      <div class="card-content">
        <small>
          Here are some key resources for the topic
        </small>
        <ul>
          <li><a href="https://1drv.ms/p/s!AiBfiXC6wirjj8MQ7wTzHDdHXmnmqQ?e=BHaAd3" target="_blank"><i class="fad fa-presentation-screen"></i> Full Slides</a></li>
          <li><a href="https://1drv.ms/p/s!AiBfiXC6wirjj7570ATmIwXyzXGLiQ?e=7BhFje" target="_blank"><i class="fad fa-presentation-screen"></i> Lightning Talk Slides</a></li>
          <li><a target="_blank" href="https://github.com/IntegerMan/DieHardSolver"><i class="fab fa-github"></i> Die Hard Classification GitHub Repository</a></li>
          <li><a target="_blank" href="https://AccessibleAI.dev"><i class="fad fa-globe"></i> Accessible AI (Data Science Blog)</a></li>
          <li><a target="_blank" href="https://MattOnDataScience.com"><i class="fab fa-youtube"></i> Matt on Data Science YouTube Channel</a></li>
        </ul>
      </div>
    </div>    
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          More on Die Hard
        </p>
      </header>
      <div class="card-content">
        <small>
          For some additional learning, see these materials:
        </small>
        <ul>
          <li><a href="https://accessibleai.dev/post/diehard/" target="_blank"><i class="fad fa-file-exclamation swap"></i> Is Die Hard a Christmas Movie? (Automated ML only)</a></li>
          <li><a href="https://accessibleai.dev/post/diehardautoml/" target="_blank"><i class="fad fa-code"></i> Using AutoML, Python, &amp; Azure to Classify Die Hard</a></li>
          <li><a href="https://www.youtube.com/watch?v=WUJT2wpCjT0" target="_blank"><i class="fab fa-youtube"></i> Project Overview YouTube Video</a></li>
        </ul>
      </div>
    </div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Azure ML Materials
        </p>
      </header>
      <div class="card-content">
        <small>
          If your focus is on the Azure ML overall, these are good articles to start with.
        </small>
        <ul>
          <li><a target="_blank" href="https://accessibleai.dev/post/azuremltour/"><i class="fad fa-cloud-plus swap"></i> Azure Machine Learning Studio Tour</a></li>
          <li><a target="_blank" href="https://accessibleai.dev/post/interpreting_confusion_matrixes/"><i class="fad fa-table"></i> Understanding Confusion Matrixes</a></li>
          <li><a target="_blank" href="https://accessibleai.dev/post/regression_metrics/"><i class="fad fa-chart-line"></i> Understanding Regression Metrics</a></li>
          <li><a target="_blank" href="https://accessibleai.dev/post/azureml_compute_resources/"><i class="fad fa-microchip"></i> Azure Machine Learning Compute Resources</a></li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import YouTubeEmbed from "@/components/YouTubeEmbed";
export default {
  name: "DieHardView",
  components: { YouTubeEmbed, AppHeader }
};
</script>

<style scoped lang="scss">

</style>
