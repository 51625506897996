<template>
  <h1>Chat</h1>
  <chat-component />
</template>

<script>
import ChatComponent from "@/components/ChatComponent";
export default {
  name: "ChatView",
  components: { ChatComponent }
};
</script>

<style scoped>

</style>
