<template>
  <app-header header="'Stand Back; I'm going to try Data Science' Resources" message="Here's a collection of resources related to my 'Stand Back; I'm going to try Data Science' talk."/>

  <!-- YouTube Embed -->
  <you-tube-embed video="TbISSCO1EUA" />

  <section class="is-flex is-flex-wrap-wrap content-list mt-5">
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Key Resources
        </p>
      </header>
      <div class="card-content">
        <small>
          Here are the talk slides and a few recommended resources.
        </small>
        <ul>
          <li><a href="https://1drv.ms/p/s!AiBfiXC6wirjj8MXii5VMLM_cFRykA?e=RcJvBs"><i class="fad fa-presentation-screen"></i> Slides</a></li>
          <li><a href="https://MattOnDataScience.com "><i class="fab fa-youtube"></i> Matt on Data Science YouTube Channel</a></li>
          <li><a href="https://AccessibleAI.dev "><i class="fad fa-globe"></i> Accessible AI (Data Science Blog)</a></li>
          <li><a href="https://accessibleai.dev/post/coremltasks/"><i class="fad fa-hockey-puck"></i> Predicting Hockey Penalties with Azure Machine Learning (Article &amp; Video)</a></li>
          <li><a href="https://accessibleai.dev/post/anaconda/"><i class="fad fa-snake"></i> Getting Started with Jupyter Notebooks &amp; Anaconda (Article &amp; Video)</a></li>
        </ul>
      </div>
    </div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Conceptual Details
        </p>
      </header>
      <div class="card-content">
        <small>
          If you'd like some additional details or review on conceptual areas, check these out:
        </small>
        <ul>
          <li><a href="https://accessibleai.dev/post/aivsml/"><i class="fad fa-circle"></i> AI vs Machine Learning vs Deep Learning (Article &amp; Video)</a></li>
          <li><a href="https://accessibleai.dev/post/datascience/"><i class="fad fa-question"></i> What is Data Science? (Article &amp; Video)</a></li>
          <li><a href="https://accessibleai.dev/post/datascienceroles/"><i class="fad fa-people"></i> Roles in Data Science (Article &amp; Video)</a></li>
          <li><a href="https://accessibleai.dev/post/responsible-ai/"><i class="fad fa-scale-balanced"></i> Microsoft's Responsible AI Principles (Article &amp; Video)</a></li>
          <li><a href="https://bit.ly/ds-trends"><i class="fad fa-chart-line"></i> Data Science Trends</a></li>
        </ul>

      </div>
    </div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Learning Paths
        </p>
      </header>
      <div class="card-content">
        <small>
          If you're looking for more structured learning, these are some resources I'd recommend
        </small>
        <ul>
          <li><a href="https://docs.microsoft.com/en-us/learn/browse/?WT.mc_id=azureml_tutorials"><i class="fab fa-microsoft"></i> MS Learn Data Science Resources</a></li>
          <li><a href="https://www.coursera.org/specializations/python-3-programming"><i class="fad fa-snake"></i> Coursera Michigan Python 3 Programming Specialization</a></li>
          <li><a href="https://www.coursera.org/specializations/statistics-with-python"><i class="fad fa-chart-candlestick"></i> Coursera Michigan Statistics with Python Specialization</a></li>
          <li><a href="https://www.coursera.org/specializations/data-science-python"><i class="fad fa-microscope"></i> Coursera Michigan Applied Data Science with Python Specialization</a></li>
          <li><a href="https://www.coursera.org/professional-certificates/ibm-data-analyst"><i class="fad fa-chart-scatter"></i> Coursera IBM Data Analyst Professional Certificate</a></li>
          <li><a href="https://www.coursera.org/professional-certificates/ibm-data-science"><i class="fad fa-flask"></i> Coursera IBM Data Science Professional Certificate</a></li>
          <li><a href="https://www.amazon.com/Ace-Data-Science-Interview-Questions/dp/0578973839"><i class="fad fa-book"></i> Ace the Data Science Interview</a></li>
        </ul>

      </div>
    </div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Documentation
        </p>
      </header>
      <div class="card-content">
        <small>
          Documentation for the major data science libraries
        </small>
        <ul>
          <li><a href="https://pandas.pydata.org/pandas-docs/stable/"><i class="fad fa-table"></i> Pandas</a></li>
          <li><a href="https://plotly.com/python/"><i class="fad fa-chart-candlestick"></i> Plotly</a></li>
          <li><a href="https://dash.plotly.com/introduction"><i class="fad fa-grid-horizontal"></i> Dash</a></li>
          <li><a href="https://scikit-learn.org/stable/tutorial/index.html"><i class="fad fa-face-thinking"></i> SciKit-Learn</a></li>
          <li><a href="https://keras.io/getting_started/"><i class="fad fa-thought-bubble"></i> Keras</a></li>
          <li><a href="https://www.tensorflow.org/guide/"><i class="fad fa-arrow-right-from-bracket"></i> TensorFlow</a></li>
          <li><a href="https://pytorch.org/docs/stable/index.html"><i class="fad fa-flashlight"></i> PyTorch</a></li>
        </ul>

      </div>
    </div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Azure Machine Learning Resources
        </p>
      </header>
      <div class="card-content">
        <small>
          Here are some additional resources mentioned during the talk:
        </small>
        <ul>
          <li><a href="https://accessibleai.dev/post/azuremltour/"><i class="fad fa-map"></i> Touring Azure Machine Learning Studio (Article &amp; Video)</a></li>
          <li><a href="https://accessibleai.dev/post/azureml/"><i class="fad fa-sparkles"></i> Creating an Azure Machine Learning Studio Instance (Article &amp; Video)</a></li>
          <li><a href="https://accessibleai.dev/post/penalty-prediction/"><i class="fad fa-question"></i> What can you do in ML.NET with C#? (Article &amp; Video)</a></li>
          <li><a href="https://accessibleai.dev/post/diehard/"><i class="fad fa-hat-santa"></i> Is Die Hard a Christmas Movie? Let's ask Azure! (Article &amp; Video)</a></li>
          <li><a href="https://accessibleai.dev/post/cognitiveservices/"><i class="fad fa-cogs"></i> Azure Cognitive Services (Article &amp; Video)</a></li>
          <li><a href="https://accessibleai.dev/post/responsible-ai/"><i class="fad fa-scale-balanced"></i> Microsoft's Responsible AI Principles (Article &amp; Video)</a></li>
          <li><a href="http://aka.ms/mlcheatsheet"><i class="fad fa-calculator-simple"></i> Machine Learning Algorithm Cheatsheet</a></li>
        </ul>

      </div>
    </div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Azure Certification Resources
        </p>
      </header>
      <div class="card-content">
        <small>
          Here are some additional resources mentioned during the talk:
        </small>
        <ul>
          <li><a href="https://accessibleai.dev/post/ai900/"><i class="fad fa-thought-bubble"></i> What I learned from the AI-900 exam (Article &amp; Video)</a></li>
          <li><a href="https://developer.microsoft.com/en-us/offers/30-days-to-learn-it"><i class="fad fa-list-check"></i> Microsoft Learn: 30 Days to Learn It</a></li>
          <li><a href="https://aka.ms/AzureCerts_AIFundamentals?WT.mc_id=Azure_BoM-wwl"><i class="fad fa-microchip-ai"></i> Azure AI Fundamentals (AI-900)</a></li>
          <li><a href="https://aka.ms/AzureCerts_DataFundamentals?WT.mc_id=Azure_BoM-wwl"><i class="fad fa-database"></i> Azure Data Fundamentals (DP-900)</a></li>
          <li><a href="https://aka.ms/AzureCerts_AIEngineer?WT.mc_id=Azure_BoM-wwl"><i class="fad fa-toolbox"></i> Azure AI Engineer (AI-102)</a></li>
          <li><a href="https://aka.ms/AzureCerts_DataScientist?WT.mc_id=Azure_BoM-wwl"><i class="fad fa-flask"></i> Azure Data Scientist (DP-100)</a></li>
          <li><a href="https://aka.ms/AzureCerts_DataEngineer?WT.mc_id=Azure_BoM-wwl"><i class="fad fa-wrench"></i> Azure Data Engineer (DP-203)</a></li>
          <li><a href="https://aka.ms/AzureCerts_DataAnalyst?WT.mc_id=Azure_BoM-wwl"><i class="fad fa-chart-scatter"></i> Azure Data Analyst (DA-100)</a></li>
          <li><a href="https://aka.ms/AzureCerts_DatabaseAdmin?WT.mc_id=Azure_BoM-wwl"><i class="fad fa-database"></i> Azure Database Administrator (DP-300)</a></li>
        </ul>

      </div>
    </div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          ML.NET &amp; .NET Machine Learning Resources
        </p>
      </header>
      <div class="card-content">
        <small>
          Here are some additional resources mentioned during the talk:
        </small>
        <ul>
          <li><a href="https://accessibleai.dev/post/ml_net-video-game-classification/"><i class="fad fa-gamepad-modern"></i> Classifying Video Game ESRB Ratings with ML.NET (Article &amp; Video)</a></li>
          <li><a href="https://accessibleai.dev/post/ml_net_tasks/"><i class="fad fa-question"></i> What can you do with ML.NET and C#? (Article &amp; Video)</a></li>
          <li><a href="https://dev.to/integerman/breeding-attack-squirrels-in-f-7m1"><i class="fad fa-acorn"></i> Genetic Algorithms and Attack Squirrels (Article)</a></li>
        </ul>
      </div>
    </div>

    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Social Media
        </p>
      </header>
      <div class="card-content">
        <small>
          I'm active on social media in a few key areas and this is the best way to stay in touch and keep
          up with my latest content and activities.
        </small>
        <ul>
          <li><a href="https://www.twitter.com/IntegerMan"><i class="fab fa-twitter"></i> Twitter</a></li>
          <li><a href="https://LinkedIn.com/in/matteland"><i class="fab fa-linkedin"></i> LinkedIn
            <small>(Please include a note when connecting)</small></a>
          </li>
          <li><a href="https://www.youtube.com/c/MattEland"><i class="fab fa-youtube"></i> YouTube</a></li>
          <li><a href="https://github.com/IntegerMan"><i class="fab fa-github"></i> GitHub</a></li>
          <li><a href="https://sessionize.com/matt-eland/"><i class="fad fa-chalkboard-teacher swap"></i>
            Sessionize</a></li>
          <li><a href="https://app.behearty.co/user/matt-eland-4d49bfdc"><i
            class="fad fa-head-side-heart"></i>
            Hearty</a></li>
          <li><a href="https://integerman.itch.io/"><i class="fad fa-gamepad-alt"></i> Itch.io</a>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <h2>Featured Content</h2>
  <section class="columns featured mt-2">
    <article class="column">
      <a href="https://mattondatascience.com">
        <div class="card">
          <div class="card-image">
            <figure class="image">
              <img src="@/assets/MattOnDS.png" alt="Matt on Data Science Screenshot">
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image is-48x48">
                  <img src="@/assets/Beaker.png" alt="Matt on Data Science Icon">
                </figure>
              </div>
              <div class="media-content">
                <p class="title is-4"><a href="https://mattondatascience.com">Matt on Data Science</a></p>
                <p class="subtitle is-6">YouTube Content on Data Science</p>
              </div>
            </div>
          </div>
          <!-- <footer class="card-footer">
              <a href="https://mattondatascience.com" class="card-footer-item">Visit Site</a>
          </footer> -->
        </div>
      </a>
    </article>
    <article class="column">
      <a href="https://accessibleai.dev">
        <div class="card">
          <div class="card-image">
            <figure class="image">
              <img src="@/assets/AccessibleAI.png" alt="AccessibleAI.dev Screenshot">
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image is-48x48">
                  <img src="@/assets/Beaker.png" alt="Accessible AI Icon">
                </figure>
              </div>
              <div class="media-content">
                <p class="title is-4"><a href="https://AccessibleAI.dev">Accessible AI</a></p>
                <p class="subtitle is-6">Articles on data science in the Azure cloud</p>
              </div>
            </div>
          </div>
          <!-- <footer class="card-footer">
              <a href="https://accessibleai.dev" class="card-footer-item">Visit Site</a>
          </footer> -->
        </div>
      </a>
    </article>
    <article class="column">
      <a href="https://KillAllDefects.com">
        <div class="card">
          <div class="card-image">
            <figure class="image">
              <img src="@/assets/KillAllDefects.png" alt="KillAllDefects.com Screenshot">
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image is-48x48">
                  <img src="@/assets/KADLogo.png" alt="Kill All Defects Icon">
                </figure>
              </div>
              <div class="media-content">
                <p class="title is-4"><a href="https://KillAllDefects.com">Kill All Defects</a></p>
                <p class="subtitle is-6">Articles on software engineering</p>
              </div>
            </div>
          </div>
          <!-- <footer class="card-footer">
              <a href="https://accessibleai.dev" class="card-footer-item">Visit Site</a>
          </footer> -->
        </div>
      </a>
    </article>
  </section>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import YouTubeEmbed from "@/components/YouTubeEmbed";
export default {
  name: "TryDataScienceView",
  components: { YouTubeEmbed, AppHeader }
};
</script>

<style scoped lang="scss">

</style>
