<template>
  <header v-if="notOnHomePage" id="navHeader">
    <router-link :to="{ name: 'Home' }" title="Navigate back to the home page"
      ><i class="fas fa-arrow-left"></i> Back</router-link
    >
  </header>

  <main>
    <router-view />
  </main>

  <footer>
    Copyright &copy; 2024
    <router-link :to="{name: 'ContactMe'}">Matt Eland</router-link>; All Rights
    Reserved
  </footer>
</template>

<script>
export default {
  computed: {
    notOnHomePage() {
      return this.$route.name !== "Home";
    },
  },
};
</script>

<style lang="scss">
@import "./styles/styles.scss";

#navHeader {
  margin-bottom: 1rem;
}
</style>
