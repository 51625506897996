<template>
  <app-header header="Talks" message="Here are a collection of talks I give and resources for past talks" />
  <h2>Talk Resources</h2>

  <talk-resources-component />

  <section class="is-flex is-flex-wrap-wrap content-list">
    <current-talks-component />
    <upcoming-talks-component />
    <past-talks-component />
  </section>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import UpcomingTalksComponent from "@/components/UpcomingTalksComponent";
import PastTalksComponent from "@/components/PastTalksComponent";
import CurrentTalksComponent from "@/components/CurrentTalksComponent";
import TalkResourcesComponent from "@/components/TalkResourcesComponent";

export default {
  name: "TalksView",
  components: {
    TalkResourcesComponent,
    CurrentTalksComponent,
    PastTalksComponent,
    UpcomingTalksComponent,
    AppHeader,
  },
};
</script>
