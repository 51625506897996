<template>
  <app-header header="Automating my Dog with Azure Cognitive Services Resources" message="Here's a collection of resources related to my 'Automated my Dog with Azure Cognitive Services' talk."/>

  <!-- YouTube Embed -->
  <you-tube-embed video="wmY4lNV0wZE" />

  <section class="is-flex is-flex-wrap-wrap content-list mt-5">
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Key Resources
        </p>
      </header>
      <div class="card-content">
        <small>
          Here are the slides and code from the talk
        </small>
        <ul>
          <li><a href="https://1drv.ms/p/s!AiBfiXC6wirjj-5fH4FOzWOvHhv4FA?e=6j0ZD1" target="_blank"><i class="fad fa-presentation-screen"></i> Slides</a></li>
          <li><a href="https://github.com/IntegerMan/AutomatingMyDog" target="_blank"><i class="fab fa-github"></i> Automating My Dog GitHub Repository</a></li>
        </ul>
      </div>
    </div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Additional Learning
        </p>
      </header>
      <div class="card-content">
        <small>
          For some additional learning, see these materials:
        </small>
        <ul>
          <li><a href="https://docs.microsoft.com/en-us/learn/roles/ai-engineer"  target="_blank"><i class="fad fa-books swap"></i> Microsoft Learn Resources</a></li>
          <li><a href="https://MattOnDataScience.com" target="_blank"><i class="fab fa-youtube"></i> Matt on Data Science YouTube Channel</a></li>
          <li><a href="https://www.youtube.com/playlist?list=PL_4SjWezd5HyRTJ4xCvfVBGg0Qehbk27U" target="_blank"><i class="fad fa-play"></i> Matt on Data Science - Azure Cognitive Services YouTube Playlist</a></li>
          <li><a href="https://AccessibleAI.dev" target="_blank"><i class="fad fa-globe"></i> Accessible AI (Data Science Blog)</a></li>
          <li><a href="https://accessibleai.dev/tags/cognitive-services/" target="_blank"><i class="fad fa-tag"></i> Accessible AI - Azure Cognitive Services Tag</a></li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import YouTubeEmbed from "@/components/YouTubeEmbed";
export default {
  name: "DoggoView",
  components: { YouTubeEmbed, AppHeader }
};
</script>

<style scoped lang="scss">

</style>
