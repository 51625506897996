<template>
  <app-header header="Visualizing Code Resources" message="Here's a collection of resources related to my 'Visualizing Code' talk."/>

  <!-- YouTube Embed -->
  <you-tube-embed video="9QgkIkiMscw" />

  <section class="is-flex is-flex-wrap-wrap content-list mt-5">
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Key Resources
        </p>
      </header>
      <div class="card-content">
        <small>
          Here are the talk slides and code. This is likely what you're looking for.
        </small>
        <ul>
          <li><a href="https://1drv.ms/p/s!AiBfiXC6wirjjtUH61MA7dj0hBZQ0w?e=0CEFG5"><i class="fad fa-presentation-screen"></i> Slides</a></li>
          <li><a href="https://github.com/IntegerMan/VisualizingCode"><i class="fab fa-github"></i> Data Visualization Repository</a></li>
          <li><a href="https://accessibleai.dev/post/anaconda/"><i class="fad fa-snake"></i> Getting Started with Jupyter Notebooks &amp; Anaconda</a></li>
        </ul>
      </div>
    </div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          OpenRA &amp; Analysis Projects
        </p>
      </header>
      <div class="card-content">
        <small>
          Check out these links for information specific to the OpenRA project, including code analysis.
        </small>
        <ul>
          <li><a href="https://www.openra.net/"><i class="fad fa-gamepad-modern"></i> OpenRA Project Page</a></li>
          <li><a href="https://github.com/OpenRA/OpenRA"><i class="fab fa-github"></i> OpenRA Repository</a></li>
          <li><a href="https://codescene.io/projects/22087"><i class="fad fa-circle-notch"></i> CodeScene Project</a></li>
          <li><a href="https://sonarcloud.io/project/overview?id=IntegerMan_OpenRA"><i class="fad fa-radar"></i> SonarCloud Project</a></li>
          <li><a href="https://NDepend.com"><i class="fad fa-arrow-right"></i> NDepend Product Page</a></li>
        </ul>
      </div>
    </div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Additional Resources
        </p>
      </header>
      <div class="card-content">
        <small>
          Here are some additional resources mentioned during the talk:
        </small>
        <ul>

          <li><a href="https://MattOnDataScience.com "><i class="fab fa-youtube"></i> Matt on Data Science Channel</a></li>
          <li><a href="https://plotly.com/python/"><i class="fad fa-chart-candlestick"></i> Plotly Documentation</a></li>
          <li><a href="https://pandas.pydata.org/pandas-docs/stable/"><i class="fad fa-table"></i> Pandas Documentation</a></li>
          <li><a href="https://killalldefects.com/2021/10/08/github-next-repo-visualization/"><i class="fab fa-github-alt"></i> Repository Visualization with GitHub Next</a></li>
          <li><a href="https://cli.github.com/"><i class="fad fa-terminal"></i> GitHub CLI</a></li>
          <li><a href="https://pydriller.readthedocs.io/en/latest/"><i class="fad fa-down-to-line"></i> PyDriller</a></li>
          <li><a href="https://www.amazon.com/Books-Adam-Tornhill/s?rh=n%3A283155%2Cp_27%3AAdam+Tornhill"><i class="fad fa-books"></i> Adam Tornhill's Books</a></li>
        </ul>

      </div>
    </div>

    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          Social Media
        </p>
      </header>
      <div class="card-content">
        <small>
          I'm active on social media in a few key areas and this is the best way to stay in touch and keep
          up with my latest content and activities.
        </small>
        <ul>
          <li><a href="https://www.twitter.com/IntegerMan"><i class="fab fa-twitter"></i> Twitter</a></li>
          <li><a href="https://LinkedIn.com/in/matteland"><i class="fab fa-linkedin"></i> LinkedIn
            <small>(Please include a note when connecting)</small></a>
          </li>
          <li><a href="https://www.youtube.com/c/MattEland"><i class="fab fa-youtube"></i> YouTube</a></li>
          <li><a href="https://github.com/IntegerMan"><i class="fab fa-github"></i> GitHub</a></li>
          <li><a href="https://sessionize.com/matt-eland/"><i class="fad fa-chalkboard-teacher swap"></i>
            Sessionize</a></li>
          <li><a href="https://app.behearty.co/user/matt-eland-4d49bfdc"><i
            class="fad fa-head-side-heart"></i>
            Hearty</a></li>
          <li><a href="https://integerman.itch.io/"><i class="fad fa-gamepad-alt"></i> Itch.io</a>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <h2>Featured Content</h2>
  <section class="columns featured mt-2">
    <article class="column">
      <a href="https://mattondatascience.com">
        <div class="card">
          <div class="card-image">
            <figure class="image">
              <img src="@/assets/MattOnDS.png" alt="Matt on Data Science Screenshot">
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image is-48x48">
                  <img src="@/assets/Beaker.png" alt="Matt on Data Science Icon">
                </figure>
              </div>
              <div class="media-content">
                <p class="title is-4"><a href="https://mattondatascience.com">Matt on Data Science</a></p>
                <p class="subtitle is-6">YouTube Content on Data Science</p>
              </div>
            </div>
          </div>
          <!-- <footer class="card-footer">
              <a href="https://mattondatascience.com" class="card-footer-item">Visit Site</a>
          </footer> -->
        </div>
      </a>
    </article>
    <article class="column">
      <a href="https://accessibleai.dev">
        <div class="card">
          <div class="card-image">
            <figure class="image">
              <img src="@/assets/AccessibleAI.png" alt="AccessibleAI.dev Screenshot">
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image is-48x48">
                  <img src="@/assets/Beaker.png" alt="Accessible AI Icon">
                </figure>
              </div>
              <div class="media-content">
                <p class="title is-4"><a href="https://AccessibleAI.dev">Accessible AI</a></p>
                <p class="subtitle is-6">Articles on data science in the Azure cloud</p>
              </div>
            </div>
          </div>
          <!-- <footer class="card-footer">
              <a href="https://accessibleai.dev" class="card-footer-item">Visit Site</a>
          </footer> -->
        </div>
      </a>
    </article>
    <article class="column">
      <a href="https://KillAllDefects.com">
        <div class="card">
          <div class="card-image">
            <figure class="image">
              <img src="@/assets/KillAllDefects.png" alt="KillAllDefects.com Screenshot">
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image is-48x48">
                  <img src="@/assets/KADLogo.png" alt="Kill All Defects Icon">
                </figure>
              </div>
              <div class="media-content">
                <p class="title is-4"><a href="https://KillAllDefects.com">Kill All Defects</a></p>
                <p class="subtitle is-6">Articles on software engineering</p>
              </div>
            </div>
          </div>
          <!-- <footer class="card-footer">
              <a href="https://accessibleai.dev" class="card-footer-item">Visit Site</a>
          </footer> -->
        </div>
      </a>
    </article>
  </section>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import YouTubeEmbed from "@/components/YouTubeEmbed";
export default {
  name: "VisualizingCodeView",
  components: { YouTubeEmbed, AppHeader }
};
</script>

<style scoped lang="scss">

</style>
