<template>
  <header class="greeting">
    <figure class="profile ml-2 mr-5 mt-0 mb-0">
        <img
          class="is-rounded"
          src="@/assets/ProfileMattE.png"
          alt="Matt Eland's Profile Picture"
        />
      </figure>
      <figure class="mvp ml-2 mr-5 mt-0">
        <a href="https://mvp.microsoft.com/en-us/PublicProfile/5005207?fullName=Matt%20Eland">
        <img
          src="@/assets/MVP.png"
          alt="Microsoft MVP Award Badge"
        />
      </a>
      </figure>
    <section class="headline mb-0">
      <h1>
        Hi, I'm Matt and I help people love Software Engineering &amp; Data Science
      </h1>
      <p>
        This site contains links to my content channels, socials, and resources for my conference and user group talks.
      </p>
      <p class="mt-2">
        If there's something you're curious about, please <router-link :to="{ name: 'ContactMe' }">contact me</router-link>.
      </p>
    </section>
    <section class="mb-0 blurb" style="display: flex; flex-direction: column; justify-items: flex-end; justify-content: flex-end;">
      <h2 class="authorName mt-0">Matt Eland</h2>
      <p>
        <a href="https://mvp.microsoft.com/en-us/PublicProfile/5005207?fullName=Matt%20Eland">Microsoft MVP in AI</a>
      </p>
      <p>
        Author of <a href="https://www.amazon.com/Refactoring-Safely-improve-applications-technical/dp/1835089984/">Refactoring with C#</a> 
        <strong> (Packt Book)</strong> and <a href="https://www.linkedin.com/learning/azure-ai-engineer-associate-ai-102-cert-prep-implement-computer-vision-solutions/introducing-computer-vision-on-azure">Implement Computer Vision Solutions</a> 
        <strong> (LinkedIn Learning Course)</strong>
      </p>
      <p>
        <strong>AI Specialist</strong> &amp; <strong>Senior Solutions Developer II</strong> at <a href="https://www.LeadingEDJE.com/">Leading EDJE</a>
      </p>
      <p>
        Microsoft Certified <a href="https://www.credly.com/badges/53ace869-2160-4fcd-be00-271bc5ada4aa">Azure Data Scientist</a> &amp; <a href="https://www.credly.com/badges/ff77beed-645f-4d8f-9834-c8177915cbc8/public_url">Azure AI Engineer</a> Associate
      </p>
    </section>
  </header>
</template>

<script>
export default {
  name: "AppHeader",
};
</script>

<style scoped>

.profile img {
  width: 181px;
}
</style>
