<template>
  <h1>Not Found</h1>
  <p>
    Uh oh! The resource you were looking for could not be found.
  </p>
  <p>
    Please check your URL and try again. If the problem persists, <router-link :to="{name: 'ContactMe'}">contact me</router-link>.
  </p>
</template>

<script>
export default {
  name: "NotFoundView",
};
</script>

<style scoped lang="scss">

p {
  margin-bottom: 1rem;
}

</style>
