<template>
  <iframe src='https://webchat.botframework.com/embed/singularity-sally?s=a3Bb34zSdxI.us6IsM-isKAHqrbXG1AcLedqTSu5EmE40uUObbq_RjE'  style='min-width: 400px; width: 100%; min-height: 600px;'></iframe>
</template>

<script>
export default {
  name: "ChatComponent"
};
</script>

<style scoped lang="scss">

</style>
