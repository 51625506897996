<template>
  <li>
    <a :href="event.link" target="_blank">
      {{event.name}}
    </a>
    <small>
                  <span v-if="event.location">
                    <strong>{{event.location}}</strong>
                  </span>
      <span v-if="event.date">
                    on <strong>{{event.date}}</strong>
                  </span>
      <span v-if="event.time">
                    at <strong>{{event.time}}</strong>
                  </span>
      <span v-if="event.room">
                    in <strong>{{event.room}}</strong>
                  </span>
    </small>
  </li>

</template>

<script>
export default {
  name: "TalkListItemComponent",
  props:
    {
      event: {
        type: Object,
        required: true,
      }
    }
};
</script>

<style scoped lang="scss">

small {
  margin-left: 0.5rem;
}

strong {
  color: var(--accent);
}
</style>
