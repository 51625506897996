<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Currently Offered Talks</p>
    </header>
    <div class="card-content">
      <small>
        I speak somewhat regularly at user groups and conferences within my area. Here are the current
        talk abstracts I have available on <a href="https://sessionize.com/matt-eland/">Sessionize</a>.
      </small>
      <talks-list-component :include-not-delivered="true" :include-past="true" :include-upcoming="true" :show-delivered="false" :show-upcoming="false" :show-details="false" />
    </div>
  </div>
</template>

<script>
import TalksListComponent from "@/components/TalksListComponent";
export default {
  name: "CurrentTalksComponent",
  components: { TalksListComponent  },
};
</script>

<style scoped lang="scss">
</style>
