<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Upcoming Talks</p>
    </header>
    <div class="card-content">
      <small>
        Online and in-person conference and user group talks will usually appear
        here, though not all events will be listed. Check
        <a href="https://sessionize.com/matt-eland/">my Sessionize profile</a>
        for a more details on future events.
      </small>
      <talks-list-component :include-not-delivered="false" :include-past="false" :include-upcoming="true" :show-upcoming="true" :show-delivered="false" />
    </div>
  </div>
</template>

<script>
import TalksListComponent from "@/components/TalksListComponent";
export default {
  name: "UpcomingTalksComponent",
  components: { TalksListComponent  },
};
</script>

<style scoped lang="scss">
</style>
