<template>
  <section class="mt-4 mb-2">
    Looking for additional content links for some of my talks? Check out the
    following below:
    <ul class="mt-1">
      <li>
        <router-link :to="{ name: 'Jupyter' }"
        ><i class="fad fa-snake"></i> Jupyter Workshop</router-link
        >
        <small> (<a href="https://aiconusa.techwell.com/">AI Con USA</a>)</small>
      </li>
      <li>
        <router-link :to="{ name: 'Doggo' }"
        ><i class="fad fa-dog swap"></i> Automating my Dog with Azure
          Cognitive Services
        </router-link>
        <small>
          (<a
          href="https://kcdc.info"
        >KCDC 2023</a
        >, <a
          href="https://www.scifidevcon.com/courses/2023-may-the-fourth-event"
        >SciFiDevCon 2023</a
        >, <a
          href="https://www.meetup.com/central-ohio-net-developers-group-condg/events/283748967/"
        >CONDG</a
        >)</small
        >
      </li>
      <li>
        <router-link :to="{ name: 'DieHard' }"
        ><i class="fad fa-hat-santa swap"></i> Is Die Hard a Christmas Movie? Let's ask Azure!
        </router-link>
        <small>(Basically everywhere)</small>
      </li>
      <li>
        <router-link :to="{ name: 'IntroducingAutoML' }"
        ><i class="fad fa-microchip"></i> Automated ML with Python and
          the Azure ML SDK
        </router-link>
        <small>(<a href="https://indycode.amegala.com/">Indy.Code()</a>, <a href="https://pyohio.org">PyOhio</a>)</small>
      </li>
      <li>
        <router-link :to="{ name: 'TryDS' }"
        ><i class="fad fa-flask"></i> Stand Back; I'm going to try Data
          Science!
        </router-link>
        <small
        >(<a href="https://stirtrek.com/" target="_blank">Stir Trek</a>,
          <a href="https://www.codemash.org/" target="_blank">CodeMash</a
          >)</small
        >
      </li>
      <li>
        <router-link :to="{ name: 'VisualizingCode' }"
        ><i class="fad fa-code"></i> Visualizing Code</router-link
        >
        <small>(<a href="https://www.codemash.org/">CodeMash</a>)</small>
      </li>

    </ul>
  </section>
</template>

<script>
export default {
  name: "TalkResourcesComponent"
};
</script>

<style scoped>

</style>
